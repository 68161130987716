import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "plugins"
    }}>{`Plugins`}</h1>
    <p>{`MDX uses `}<a parentName="p" {...{
        "href": "https://github.com/remarkjs/remark"
      }}>{`remark`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/rehypejs/rehype"
      }}>{`rehype`}</a>{` internally.
These ecosystems focus on plugins that transform syntax trees.
This may sound very much like what MDX does.
In fact, that’s what we’re doing: the core of MDX is mostly plugins itself!
We first use `}<inlineCode parentName="p">{`remark-mdx`}</inlineCode>{` to add MDX syntax support and then use a rehype
plugin to transpile it to JSX.
The final result is JSX that can be used in React/Preact/Vue/etc.`}</p>
    <p>{`The flow of MDX consists of the following six steps:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Parse`}</strong>{`: MDX text => MDAST`}</li>
      <li parentName="ol"><strong parentName="li">{`Transpile`}</strong>{`: MDAST => MDXAST (remark-mdx)`}</li>
      <li parentName="ol"><strong parentName="li">{`Transform`}</strong>{`: remark plugins applied to AST`}</li>
      <li parentName="ol"><strong parentName="li">{`Transpile`}</strong>{`: MDXAST => MDXHAST`}</li>
      <li parentName="ol"><strong parentName="li">{`Transform`}</strong>{`: rehype plugins applied to AST`}</li>
      <li parentName="ol"><strong parentName="li">{`Generate`}</strong>{`: MDXHAST => JSX text`}</li>
    </ol>
    <p>{`MDX allows you to hook into this flow at step 3 and 5, where you can use remark
and rehype plugins (respectively) to benefit from their ecosystems.`}</p>
    <p>{`There are plugins to `}<a parentName="p" {...{
        "href": "https://github.com/zeit/remark-capitalize"
      }}>{`capitalize titles`}</a>{`, to `}<a parentName="p" {...{
        "href": "https://github.com/remarkjs/remark-toc"
      }}>{`generate a
table of contents`}</a>{`, and many more.`}</p>
    <p>{`For more information, see the following links:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/remarkjs/remark/blob/master/doc/plugins.md#list-of-plugins"
        }}>{`List of remark plugins`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/rehypejs/rehype/blob/master/doc/plugins.md#list-of-plugins"
        }}>{`List of rehype plugins`}</a></li>
    </ul>
    <p>{`Creating a plugin for MDX is mostly the same as creating a plugin for remark
or rehype.
We wrote a guide on how to `}<a parentName="p" {...{
        "href": "/guides/writing-a-plugin"
      }}>{`write a plugin`}</a>{`, and the unified website has another good guide on how to `}<a parentName="p" {...{
        "href": "https://unified.js.org/create-a-plugin.html"
      }}>{`create a plugin`}</a>{`.
To see how the syntax trees in MDX deviate from the syntax trees used in remark
or rehype, see `}<a parentName="p" {...{
        "href": "/advanced/ast"
      }}>{`the docs for the AST`}</a>{`.`}</p>
    <h2 {...{
      "id": "using-remark-and-rehype-plugins"
    }}>{`Using remark and rehype plugins`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`remarkPlugins`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array`}{`[`}{`]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of remark plugins to manipulate the MDXAST`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rehypePlugins`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array`}{`[`}{`]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of rehype plugins to manipulate the MDXHAST`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`To use plugins, pass them under their respective name.
They are passed in the options to the core MDX library, but you’d typically pass
them through a loader like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const images = require('remark-images')
const emoji = require('remark-emoji')

module.exports = {
  module: {
    rules: [
      {
        test: /\\.mdx?$/,
        use: [
          {
            loader: 'babel-loader'
          },
          {
            loader: '@mdx-js/loader',
            options: {
              remarkPlugins: [images, emoji]
            }
          }
        ]
      }
    ]
  }
}
`}</code></pre>
    <p>{`If you’re using MDX directly, they can be passed like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const fs = require('fs')
const mdx = require('@mdx-js/mdx')
const images = require('remark-images')
const emoji = require('remark-emoji')

const mdxText = fs.readFileSync('hello.mdx', 'utf8')
const jsx = mdx.sync(mdxText, {
  remarkPlugins: [images, emoji]
})
`}</code></pre>
    <p>{`Some plugins can be configured and accept their own options.
In that case, use the `}<inlineCode parentName="p">{`[plugin, pluginOptions]`}</inlineCode>{` syntax, like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`mdx.sync(mdxText, {
  remarkPlugins: [
    images,
    [emoji, { padSpaceAfter: true }]
})
`}</code></pre>
    <p>{`The above example ensures that `}<inlineCode parentName="p">{`padSpaceAfter`}</inlineCode>{` is only passed as options to
the `}<inlineCode parentName="p">{`emoji`}</inlineCode>{` plugin.`}</p>
    <h2 {...{
      "id": "using-retext-plugins"
    }}>{`Using retext plugins`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/retextjs/retext"
      }}>{`retext`}</a>{` plugins are, like remark and rehype plugins, really useful.
But it’s currently not possible for MDX to convert the syntax tree to nlcst
(which is used by retext) as it wouldn’t be possible to correctly convert it
back.
This means that we can’t use retext plugins directly.
See `}<a parentName="p" {...{
        "href": "https://github.com/remarkjs/remark/issues/224"
      }}>{`remarkjs/remark#224`}</a>{` for more info.`}</p>
    <p>{`Luckily, it’s possible to build a custom plugin that visits all text nodes with
`}<a parentName="p" {...{
        "href": "https://github.com/syntax-tree/unist-util-visit"
      }}><inlineCode parentName="a">{`unist-util-visit`}</inlineCode></a>{` and process them using retext (here we use
`}<a parentName="p" {...{
        "href": "https://github.com/retextjs/retext-smartypants"
      }}><inlineCode parentName="a">{`retext-smartypants`}</inlineCode></a>{` as an example).
This works in most cases, and could look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const visit = require('unist-util-visit')
const retext = require('retext')
const retextSmartypants = require('retext-smartypants')
const mdx = require('@mdx-js/mdx')

function remarkSmartypants(options) {
  const processor = retext()
    .use(retextSmartypants, options)

  function transformer(tree) {
    visit(tree, 'text', node => {
      node.value = String(processor.processSync(node.value))
    })
  }

  return transformer
}

const mdxText = \`
> "Veni, vidi, vici." ---Julius Caesar
\`

mdx.sync(mdxText, {
  remarkPlugins: [
    // regular quotes → smart quotes,
    // triple dash → em dash
    // etc.
    [remarkSmartypants, { dashes: 'oldschool' }]
  ]
})
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      